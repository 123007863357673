/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { Dispatch } from 'redux';

import CartQuery from '../../query/Cart.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { NotificationType } from 'Store/Notification/Notification.type';
import { NetworkError } from 'Type/Common.type';
import { getCartId } from 'Util/Cart';
import { getErrorMessage } from 'Util/Request/Error';
import { fetchMutation } from 'Util/Request/Mutation';
import {
    CartDispatcher as SourceCartDispatcher,
} from 'SourceStore/Cart/Cart.dispatcher';
import {AddProductToCartOptions, UpdateProductInCartOptions} from 'Store/Cart/Cart.type';

export const CURRENT_WEBSITE = 'base';

/**
 * Product Cart Dispatcher
 * @class CartDispatcher
 * @namespace Store/Cart/Dispatcher
 */
export class CartDispatcher extends SourceCartDispatcher{
    async addProductToCart(
        dispatch: Dispatch,
        options: AddProductToCartOptions,
    ): Promise<void> {
        const { products = [], cartId: userCartId } = options;

        const cartId = userCartId || getCartId();

        if (!Array.isArray(products) || products.length === 0) {
            dispatch(showNotification(NotificationType.ERROR, __('No product data!')));

            return Promise.reject();
        }

        try {
            if (!cartId) {
                return await Promise.reject();
            }

            const { addProductsToCart: { user_errors: errors = [], cartData = {} } = {} } = await fetchMutation(
                CartQuery.getAddProductToCartMutation(cartId, products),
            );

            if (Array.isArray(errors) && errors.length > 0) {
                errors.forEach((error) => {
                    dispatch(showNotification(NotificationType.ERROR, getErrorMessage(error)));
                });

                return await Promise.resolve();
            }
            dispatch(showNotification(NotificationType.SUCCESS, __('Product was added to cart!')));
            this._updateCartData(cartData, dispatch);
        } catch (error) {
            if (!navigator.onLine) {
                dispatch(showNotification(NotificationType.ERROR, __('Not possible to fetch while offline')));

                return Promise.reject();
            }

            dispatch(showNotification(NotificationType.ERROR, getErrorMessage(error as NetworkError)));

            return Promise.reject();
        }

        return Promise.resolve();
    }

    async changeItemQty(dispatch: Dispatch, options: UpdateProductInCartOptions): Promise<string | null> {
        const { uid, quantity = 1, cartId: originalCartId } = options;

        const cartId = !originalCartId ? getCartId() : originalCartId;

        try {
            if (!cartId) {
                return await Promise.reject();
            }

            const { updateCartItems: { cartData = {} } = {} } = await fetchMutation(
                CartQuery.getUpdateCartItemsMutation({
                    cart_id: cartId,
                    cart_items: [
                        {
                            cart_item_uid: uid,
                            quantity,
                        },
                    ],
                }),
            );

            this._updateCartData(cartData, dispatch);

        } catch (error) {
            dispatch(showNotification(NotificationType.ERROR, getErrorMessage(error as NetworkError)));

            return Promise.reject();
        }
        //@ts-ignore
        return Promise.resolve();
    }
}

export default new CartDispatcher();
