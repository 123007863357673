/* eslint-disable spaced-comment */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    FieldContainer as SourceFieldContainer
} from "SourceComponent/Field/Field.container";
import {SyntheticEvent} from "react";
import {ValidationOutput} from "Type/Field.type";
import {FieldValidationOutput} from "Util/Validator/Validator.type";
import {FieldType} from "Component/Field/Field.config";
import { validate } from 'Util/Validator/Validator';
import './Field.override.style.scss';

export class FieldContainer extends SourceFieldContainer {
    validate(data?: (Event | SyntheticEvent) & ValidationOutput): boolean | FieldValidationOutput {
        const {
            validationRule: { range: { max: maxValidLength = 0 } = {} }, type, attr: { name } = {},
        } = this.props;
        const { showLengthError } = this.state;
        const newValidRule = this.handleShowLengthError();
        const value = type === FieldType.CHECKBOX || type === FieldType.RADIO
            ? !!(this.fieldRef as HTMLInputElement)?.checked
            : this.fieldRef?.value;

        if (!value && value !== '') {
            return false;
        }

        const response = validate(type === FieldType.FILE
        && typeof value === 'string'
            ? value.toLowerCase()
            //@ts-ignore
            : value, newValidRule, this.fieldRef);
        const output = response !== true ? { ...response, type, name } : response;

        // If validation is called from different object you can pass object
        // to store validation error values
        if (data && data.detail && typeof output !== 'boolean') {
            if (!data.detail.errors) {
                // eslint-disable-next-line no-param-reassign
                data.detail.errors = [];
            }

            // Validates length on submit, renders special message
            if (output.errorMessages
                && typeof value === 'string'
                && maxValidLength
                && value.length > maxValidLength
                && !showLengthError
            ) {
                this.setState({ showLengthError: true });
                output.errorMessages.unshift(__('Please enter no more than %s characters.', maxValidLength));
            }

            data.detail.errors.push(output);
        }

        // When submit and response equals true (it can be object) reset show length error
        if (response === true) {
            this.setState({ showLengthError: false });
        }

        this.setState({ validationResponse: output });

        return output;
    }
}

export default FieldContainer;
