/**
 * Amasty Special Promotions Pro compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import AmastySpecialPromotionsProQuery from '../query/AmastySpecialPromotionsPro.query';

export const extendCartQuery = (args, callback) => [
    ...callback(...args),
    AmastySpecialPromotionsProQuery.getDiscounts()
];

export default {
    'Query/Cart/Query': {
        'member-function': {
            _getPricesFields: extendCartQuery
        }
    }
};
